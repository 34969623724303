import * as actionTypes from "../actions/actionTypes";
import ObjectID from "bson-objectid";

const initialState = {
	currentCampaign: null,
	newId: new ObjectID().toString(),
	error: null,
	fetching: false,
	connected: false,
	redirect: null
};

const reducer = (state=initialState, action) => {
	switch(action.type) {
		case actionTypes.CAMPAIGNS_START:
			return {...state, fetching: true, error: null};
		case actionTypes.CAMPAIGNS_STOP:
			return {...state, fetching: false};
		case actionTypes.CAMPAIGNS_SUCCESS:
			return {...state, currentCampaign: action.campaign, newId: (action.newId === true ? new ObjectID().toString() : state.newId), 
				redirect: action.redirect, fetching: false, error: null};
		case actionTypes.CAMPAIGNS_FAILED:
			return {...state, error: action.error, fetching: false};
		case actionTypes.CAMPAIGNS_NEW_ID:
			return {...state, newId: new ObjectID().toString()};
		case actionTypes.CAMPAIGNS_CLEAR:
			return {...initialState, newId: new ObjectID().toString()};
		case actionTypes.REDIRECT:
			return {...state, fetching: false, redirect: action.redirect};
		case actionTypes.CLEAR_REDIRECT:
			return {...state, fetching: false, redirect: null};
		default: return state;
	}
};

export default reducer;