import axios from "axios";
import * as actionTypes from "./actionTypes";
import * as userActions from "./user";

import {server, setFileConfig, setJsonConfig} from "./config";

export const landingStart = () => (
	{type: actionTypes.LANDINGS_START}
);

export const landingStop = () => (
	{type: actionTypes.LANDINGS_STOP}
);

export const landingFailed = error => ({
	type: actionTypes.LANDINGS_FAILED,
	error: error
});

export const landingSuccess = (data, redirect) => ({
	type: actionTypes.LANDINGS_SUCCESS,
	page: data,
	redirect: redirect ? redirect : null
});

export const clearLanding = () => ({
	type: actionTypes.LANDINGS_CLEAR
});

export const landingRedirect = redirect => ({
	type: actionTypes.REDIRECT,
	redirect
});

export const clearLandingRedirect = () => ({
	type: actionTypes.CLEAR_REDIRECT
});

export const landingNewId = () => ({
	type: actionTypes.LANDINGS_NEW_ID
});

export const landingCurrentState = (currentState, redirect) => ({
	type: actionTypes.LANDINGS_CURRENT_STATE,
	name: currentState.name,
	root: currentState.root,
	elements: currentState.elements,
	redirect: redirect ? redirect : null
});

export const setLandingPage = (token, data, redirect) => (async dispatch => {
	dispatch(landingStart());
	try {
		const response = await axios.post(server+":3500/landings/getPage", data,  setJsonConfig(token));
		dispatch(landingSuccess(response.data.page, redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(landingFailed(error.response.data.error || "Serveur indisponible, affichage impossible."));
		}
		dispatch(landingFailed("Serveur indisponible, affichage impossible."));
    }
});

export const directLanding = data => (async dispatch => {
    try {
		console.log("sending", data);
		const response = await axios.post(server+":3500/landings/directLanding", data,  setJsonConfig());
		dispatch(landingSuccess(response.data.page))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(landingFailed(error.response.data.error || "Serveur indisponible, affichage impossible."));
		}
		dispatch(landingFailed("Serveur indisponible, affichage impossible."));
    }
});

export const saveLandingPage = (token, data, redirect) => (async dispatch => {
	dispatch(landingStart());
    try {
		const response = await axios.post(server+":3500/landings/savePage", data, setJsonConfig(token));
		dispatch(landingSuccess(response.data.page, redirect));
		dispatch(userActions.userSuccess(token, response.data.user));
		dispatch(landingNewId());
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(landingFailed(error.response.data.error || "Serveur indisponible, affichage impossible."));
		}
		dispatch(landingFailed("Serveur indisponible, affichage impossible."));
    }
});

export const deleteLandingPage = (token, data, redirect) => (async dispatch => {
	dispatch(landingStart());
    try {
		const response = await axios.post(server+":3500/landings/deletePage", data, setJsonConfig(token));
		dispatch(clearLanding());
		dispatch(userActions.userSuccess(token, response.data.user));
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(landingFailed(error.response.data.error || "Serveur indisponible, suppression impossible."));
		}
		dispatch(landingFailed("Serveur indisponible, suppression impossible."));
    }
});

export const addRessource = (token, dataForm, redirect, currentState) => (async dispatch => {
	dispatch(landingStart());
    try {
		const response = await axios.post(server+":3500/landings/addRessource", dataForm, setFileConfig(token));
		dispatch(userActions.userSuccess(token, response.data.user));
		if (currentState)
			dispatch(landingCurrentState(currentState, redirect));
		else if (redirect)
			dispatch(landingRedirect(redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(landingFailed(error.response.data.error || "Serveur indisponible, ajout de ressource impossible."));
		}
		dispatch(landingFailed("Serveur indisponible, ajout de ressource impossible."));
    }
});

export const removeRessource = (token, data, redirect, currentState) => (async dispatch => {
	dispatch(landingStart());
    try {
		const response = await axios.post(server+":3500/landings/removeRessource", data, setJsonConfig(token));
		dispatch(userActions.userSuccess(token, response.data.user));
		if (currentState)
			dispatch(landingCurrentState(currentState, redirect));
		else if (redirect)
			dispatch(landingRedirect(redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(landingFailed(error.response.data.error || "Serveur indisponible, ajout de ressource impossible."));
		}
		dispatch(landingFailed("Serveur indisponible, ajout de ressource impossible."));
    }
});
