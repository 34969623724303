import * as actionTypes from "../actions/actionTypes";

const initialState = {
    results: null,
    error: null
}

const reducer = (state=initialState, action) => {
	switch(action.type) {
		case actionTypes.CAMPAIGN_RESULTS:
			return {...state, results: action.results};
        case actionTypes.CAMPAIGN_RESULTS_FAILED:
            return {...state, error: action.error};
        default: return state;
    }
};

export default reducer;
