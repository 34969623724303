import React from "react";
import PropTypes from "prop-types";

import AbstractButton from "./AbstractButton";

import classes from "./Button.module.css";

class ImageButton extends AbstractButton {

	setContent = clicked => {
		return (
			<div className={this.props.className ? this.props.className : ""}>
				<img data-test="component-button" className={classes.Image} 
				alt={this.props.alt} src={this.props.src}
				{...this.defaultEvents}
				/>	
				{this.props.to && clicked ? this.redirect() : null}
			</div>
		);
	};
}

ImageButton.propTypes = {
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired
}

export default ImageButton;