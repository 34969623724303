import * as actionTypes from "../actions/actionTypes";

const initialState = {
    preview: null,
	tmpList: null,
	currentList: null,
	error: null,
	redirect: null,
	fetching: false
};

const reducer = (state=initialState, action) => {
	//console.log("contacts", action);
	switch(action.type) {
		case actionTypes.CONTACTS_START:
			return {...state, fetching: true, redirect: null, error: null};
		case actionTypes.CONTACTS_FAILED:
			return {...state, error: action.error, fetching: false};
		case actionTypes.CONTACTS_CLEAR:
			return {...initialState};
		case actionTypes.CONTACTS_PREVIEW_SUCCESS:
			return {...state, preview: action.preview, fetching: false, redirect: null, error: null};
		case actionTypes.CONTACTS_IMPORT_SUCCESS:
			return {...state, tmpList: action.tmpList, fetching: false, redirect: null, error: null};
        case actionTypes.CONTACTS_IMPORT_CLEAR:
            return {...state, preview: null, tmpList: null, fetching: false, redirect: null, error: null};
		case actionTypes.CONTACTS_TMP_LIST:
			return {...state, tmpList: action.tmpList, fetching: false, redirect: null, error: null};
		case actionTypes.CONTACTS_CURRENT_LIST:
			return {...state, currentList: action.currentList, fetching: false, redirect: null, error: null};
			case actionTypes.REDIRECT:
				return {...state, fetching: false, redirect: action.redirect};
			case actionTypes.CLEAR_REDIRECT:
				return {...state, fetching: false, redirect: null};
			default: return state;
	}
};

export default reducer;