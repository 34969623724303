import axios from "axios";
import * as actionTypes from "./actionTypes";
import * as userActions from "./user";
import {server, setJsonConfig} from "./config";

export const feries = dates => ({
	type: actionTypes.FERIES,
	dates
});

export const feriesFailed = error => ({
	type: actionTypes.FERIES_FAILED,
	error: error
});

export const initFeries = (token) => (async dispatch => {
	try {
		const response = await axios.get(server+":3500/feries", setJsonConfig(token));
		dispatch(feries(response.data.dates))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(feriesFailed(error.response.data.error || "Serveur indisponible, initialisation impossible."));
		}
		dispatch(feriesFailed("Serveur indisponible, affichage impossible."));
    }
});
