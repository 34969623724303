import * as actionTypes from "../actions/actionTypes";

const initialState = {
	data: null,
	token: null,
	error: null,
	message: null,
	fetching: false,
	connected: false,
	redirect: null
};

const reducer = (state=initialState, action) => {
	//console.log("user", action);
	switch(action.type) {
		case actionTypes.USER_START:
			return {...state, fetching: true, error: null};
		case actionTypes.USER_SUCCESS:
			return {...state, data: action.data, token: action.token, redirect: action.redirect, fetching: false, error: null, message: action.message};
		case actionTypes.USER_FAILED:
			return {...state, error: action.error, message: null, fetching: false};
		case actionTypes.USER_CLEAR:
			return {...initialState};
		case actionTypes.USER_CONNECT:
			return {...state, connected: true};
		case actionTypes.USER_DISCONNECT:
			return {...state, connected: false};
		case actionTypes.USER_UPDATE_LIST:
			const lists = {...state.data.lists, [action.name]: action.list};
			const data = {...state.data, lists};
			return {...state, data};
		case actionTypes.REDIRECT:
			return {...state, fetching: false, redirect: action.redirect};
		case actionTypes.CLEAR_REDIRECT:
			return {...state, fetching: false, redirect: null};
		default: return state;
	}
};

export default reducer;