import React, {Fragment} from "react";
import PropTypes from "prop-types";

import AbstractButton from "./AbstractButton";

class NavButton extends AbstractButton {

	setContent = clicked => (
		<Fragment>
			{this.defaultDiv()}
			{this.props.to && clicked ? this.redirect() : null}
		</Fragment>
	);
}

NavButton.propTypes = {
	label: PropTypes.string.isRequired,
	to: PropTypes.string,
	path: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(PropTypes.string)
	]),
	location: PropTypes.object
}

export default NavButton;