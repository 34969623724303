import * as actionTypes from "../actions/actionTypes";
import ObjectID from "bson-objectid";

const initialState = {
	currentPage: null,
	newId: new ObjectID().toString(),
	error: null,
	redirect: null,
	fetching: false
};

const reducer = (state=initialState, action) => {
	switch(action.type) {
		case actionTypes.LANDINGS_START:
			return {...state, fetching: true, redirect: null, error: null};
		case actionTypes.LANDINGS_STOP:
			return {...state, fetching: false};
		case actionTypes.LANDINGS_SUCCESS:
			return {...state, currentPage: action.page, fetching: false, redirect: action.redirect, error: null};
		case actionTypes.LANDINGS_FAILED:
			return {...state, error: action.error, fetching: false};
		case actionTypes.LANDINGS_NEW_ID:
			return {...state, newId: new ObjectID().toString()};
		case actionTypes.LANDINGS_CURRENT_STATE:
			return {...state, currentPage: {...state.currentPage, name: action.name, root: action.root, elements: action.elements}, 
				fetching: false, redirect: action.redirect};
		case actionTypes.LANDINGS_CLEAR:
			return {...initialState, newId: new ObjectID().toString()};
		case actionTypes.REDIRECT:
			return {...state, fetching: false, redirect: action.redirect};
		case actionTypes.CLEAR_REDIRECT:
			return {...state, fetching: false, redirect: null};
        default: return state;
	}
};

export default reducer;