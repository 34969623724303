import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import $ from "jquery";

import ImageButton from "../Button/ImageButton";
import NavButton from "../Button/NavButton";
import {logout} from "../../store/actions";

import classes from "./Menu.module.css";
import logoutIcon from "../../images/logout.png";
import menuIcon from "../../images/menu.png";

class Menu extends Component {

    toggle = () => {
        $("."+classes.Menu).toggleClass(classes.Show);
    };

    render() {
        return (<div data-test="component-menu">
            <img className={classes.mobile} src={menuIcon} alt="Afficher le menu" onClick={this.toggle}/>
            <ul className={classes.Menu}>
            {this.props.user && this.props.user.connected ? 
                <Fragment>
                    <NavButton data-test="menu-dashboard" to="/dashboard" label="Dashboard" location={this.props.location} path="/dashboard" onClick={this.toggle} />
                    <NavButton data-test="menu-contacts" to="/contacts/create" label="Contacts" location={this.props.location} 
                        path={["/contacts/create", "/contacts/handle"]} onClick={this.toggle} />
                    <NavButton data-test="menu-landing-pages" to="/landingPages/edit" label="Landing pages" location={this.props.location} 
                        path={["/landingPages/edit", "/landingPages/handle"]} onClick={this.toggle} />
                    <NavButton data-test="menu-campaigns" to="/campaigns/edit" label="Campagnes" location={this.props.location} 
                        path={["/campaigns/edit", "/campaigns/handle"]} onClick={this.toggle} />
                    <NavButton data-test="menu-user" to="/user/infos" label="Compte" location={this.props.location} 
                        path={["/user/infos", "/user/pay", "/user/api"]} onClick={this.toggle} />
                    {this.props.user.data.isAdmin ? <NavButton data-test="menu-admin" to="/admin" label="Admin" location={this.props.location} path="/admin" onClick={this.toggle} /> : null}
                    <ImageButton data-test="menu-logout" src={logoutIcon} alt="Déconnexion" onClick={() => {this.props.logout(this.props.user.token)}} />
                </Fragment>
            :
                <Fragment>
                    <NavButton data-test="menu-welcome" label="Accueil" to="/accueil" location={this.props.location} path="/accueil" onClick={this.toggle} />
                    <NavButton data-test="menu-login" label="Connexion" to="/connect/login" location={this.props.location} path="/connect/login" onClick={this.toggle} />
                    <NavButton data-test="menu-subscribe" label="Inscription" to="/connect/subscribe" location={this.props.location} path="/connect/subscribe" onClick={this.toggle} />
                </Fragment>
            }
            </ul>
        </div>);

    }
}

const mapStateToProps = state => ({
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    logout: token => dispatch(logout(token))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));