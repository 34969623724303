import axios from "axios";
import FileDownload from "js-file-download";
import * as actionTypes from "./actionTypes";
import * as userActions from "./user";
import {server, setJsonConfig} from "./config";

export const campaignResults = results => ({
	type: actionTypes.CAMPAIGN_RESULTS,
	results
});

export const campaignResultsFailed = error => ({
	type: actionTypes.CAMPAIGN_RESULTS_FAILED,
	error: error
});

export const initCampaignResults = (token) => (async dispatch => {
	try {
		const response = await axios.get(server+":3500/campaigns/results", setJsonConfig(token));
		dispatch(campaignResults(response.data.results))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignResultsFailed(error.response.data.error || "Serveur indisponible, initialisation impossible."));
		}
		dispatch(campaignResultsFailed("Serveur indisponible, affichage impossible."));
    }
});

export const getRapport = (token, name, data) => (async dispatch => {
	try {
		console.log("Getting rapport");
		const response = await axios.post(server+":3500/campaigns/getRapport", data, {...setJsonConfig(token), responseType: "blob"});
		FileDownload(response.data, name+".xlsx");
	 } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignResultsFailed(error.response.data.error || "Erreur serveur, export impossible."));
		}
		dispatch(campaignResultsFailed("Erreur serveur, export impossible."));
	}
 });
 