export const REDIRECT = "REDIRECT";
export const CLEAR_REDIRECT = "CLEAR_REDIRECT";

export const USER_CONNECT = "USER_CONNECT";
export const USER_DISCONNECT = "USER_DISCONNECT";
export const USER_UPDATE_LIST = "USER_UPDATE_LIST";
export const USER_START = "USER_START";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_FAILED = "USER_FAILED";
export const USER_CLEAR = "USER_CLEAR";


export const CONTACTS_PREVIEW_SUCCESS = "CONTACTS_PREVIEW_SUCCESS"
export const CONTACTS_IMPORT_SUCCESS = "CONTACTS_IMPORT_SUCCESS";
export const CONTACTS_IMPORT_CLEAR = "CONTACTS_IMPORT_CLEAR";
export const CONTACTS_TMP_LIST = "CONTACTS_TMP_LIST";
export const CONTACTS_CURRENT_LIST = "CONTACTS_CURRENT_LIST";
export const CONTACTS_START = "CONTACTS_START";
export const CONTACTS_FAILED = "CONTACTS_FAILED";
export const CONTACTS_CLEAR = "CONTACTS_CLEAR";


export const LANDINGS_START = "LANDINGS_START";
export const LANDINGS_STOP = "LANDINGS_STOP";
export const LANDINGS_SUCCESS = "LANDINGS_SUCCESS";
export const LANDINGS_FAILED = "LANDINGS_FAILED";
export const LANDINGS_CLEAR = "LANDINGS_CLEAR";
export const LANDINGS_NEW_ID = "LANDINGS_NEW_ID";
export const LANDINGS_CURRENT_STATE = "LANDINGS_CURRENT_STATE";

export const CAMPAIGNS_START = "CAMPAIGNS_START";
export const CAMPAIGNS_STOP = "CAMPAIGNS_STOP";
export const CAMPAIGNS_SUCCESS = "CAMPAIGNS_SUCCESS";
export const CAMPAIGNS_FAILED = "CAMPAIGNS_FAILED";
export const CAMPAIGNS_CLEAR = "CAMPAIGNS_CLEAR";
export const CAMPAIGNS_NEW_ID = "CAMPAIGNS_NEW_ID";
export const CAMPAIGNS_CONTACTS = "CAMPAIGNS_CONTACTS";
export const CAMPAIGN_RESULTS = "CAMPAIGN_RESULTS";
export const CAMPAIGN_RESULTS_FAILED = "CAMPAIGN_RESULTS_FAILED";

export const PAYMENTS_START = "PAYMENTS_START";
export const PAYMENTS_STOP = "PAYMENTS_STOP";
export const PAYMENTS_SUCCESS = "PAYMENTS_SUCCESS";
export const PAYMENTS_FAILED = "PAYMENTS_FAILED";
export const PAYMENTS_CLEAR = "PAYMENTS_CLEAR";
export const PAYMENTS_NEW_ID = "PAYMENTS_NEW_ID";
export const PAYMENTS_POSTE_DATA = "PAYMENTS_POSTE_DATA";



export const DESTINATIONS = "DESTINATIONS";
export const DESTINATIONS_FAILED = "DESTINATIONS_FAILED";

export const FERIES = "FERIES";
export const FERIES_FAILED = "FERIES_FAILED";