import axios from "axios";
import * as actionTypes from "./actionTypes";
import * as userActions from "./user";
import {server, setJsonConfig} from "./config";

export const destinations = destinations => ({
	type: actionTypes.DESTINATIONS,
	destinations
});

export const destinationsFailed = error => ({
	type: actionTypes.DESTINATIONS_FAILED,
	error: error
});

export const initDestinations = (token) => (async dispatch => {
	try {
		const response = await axios.get(server+":3500/destinations", setJsonConfig(token));
		dispatch(destinations(response.data.destinations))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(destinationsFailed(error.response.data.error || "Serveur indisponible, initialisation impossible."));
		}
		dispatch(destinationsFailed("Serveur indisponible, affichage impossible."));
    }
});
