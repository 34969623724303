import * as actionTypes from "../actions/actionTypes";
import ObjectID from "bson-objectid";

const initialState = {
	newId: new ObjectID().toString(),
	posteData: null,
	error: null,
	redirect: null,
	fetching: false
};

const reducer = (state=initialState, action) => {
	switch(action.type) {
		case actionTypes.PAYMENTS_START:
			return {...state, fetching: true, redirect: null, error: null};
		case actionTypes.PAYMENTS_STOP:
			return {...state, fetching: false};
		// case actionTypes.PAYMENTS_SUCCESS:
		// 	return {...state, currentPage: action.page, fetching: false, redirect: action.redirect, error: null};
		case actionTypes.PAYMENTS_FAILED:
			return {...state, error: action.error, fetching: false};
		case actionTypes.PAYMENTS_NEW_ID:
			return {...state, newId: new ObjectID().toString()};
		case actionTypes.PAYMENTS_POSTE_DATA:
			return {...state, posteData: action.data, fetching: false, redirect: action.redirect};
		case actionTypes.PAYMENTS_CLEAR:
			return {...initialState, newId: new ObjectID().toString()};
		case actionTypes.REDIRECT:
			return {...state, fetching: false, redirect: action.redirect};
		case actionTypes.CLEAR_REDIRECT:
			return {...state, fetching: false, redirect: null};
        default: return state;
	}
};

export default reducer;