export const localServer = "http://192.168.230.201";
export const prodServer = "https://smsgo.fr";

export const server = prodServer;

export const setAxxesConfig = token => {
	const config = {
		headers: {"Access-Control-Allow-Origin": "*", "Content-Type": "application/json"},
		proxy: { host: "http://www.axxescaraibes.com", port: 8088}
	};
	if (token)
		config.headers.authorization = token;
	return config;
};

export const setJsonConfig = token => {
	const config = {
		headers: {"Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "Access-Control-Allow-Headers": "authorization", "Access-Control-Expose-Headers": "authorization"},
		proxy: { host: server, port: 3500}
	};
	if (token)
		config.headers.authorization = token;
	return config;
};

export const setFileConfig = token => {
	const config = {
		headers: {"Access-Control-Allow-Origin": "*", "Content-Type": "multipart/form-data", "Access-Control-Allow-Headers": "authorization", "Access-Control-Expose-Headers": "authorization"},
		proxy: { host: server, port: 3500}
	};
	if (token)
		config.headers.authorization = token;
	return config;
};

export default {
    server,
	setConfig: setJsonConfig,
	axxesConfig: setAxxesConfig
};
