import React, {Component, lazy} from 'react';
import {Switch, Route, Redirect, withRouter} from "react-router-dom";

import LoadOnCall from "./common/LoadOnCall";
import Menu from "./common/Menu/Menu";

import './App.css';
import logo from "./images/logo.png";

const Accueil = lazy(() => import("./Pages/Accueil/Accueil"));
const Admin = lazy(() => import("./Pages/Admin/Admin"));
const Campaigns = lazy(() => import("./Pages/Campaigns/Campaigns"));
const Connect = lazy(() => import("./Pages/Connect/Connect"));
const Contacts = lazy(() => import("./Pages/Contacts/Contacts"));
const Dashboard = lazy(() => import("./Pages/Dashboard/Dashboard"));
const Landing = lazy(() => import("./Pages/LandingPages/Landing/Landing"));
const LandingPages = lazy(() => import("./Pages/LandingPages/LandingPages"));
const User = lazy(() => import("./Pages/User/User"));



class App extends Component {
  render() {
    if (this.props.location.pathname.startsWith("/landing/"))
      return <Route path="/landing/:code" exact render={props => LoadOnCall(Landing, props)()} />;
    return (
        <div data-test="component-app" className="App">
          <header>
            <img src={logo} alt="SmsGO" className="Logo" />
            <Menu />
          </header>
          <div className="Page" id="page">
            <Switch>
              <Route path="/accueil" exact component={LoadOnCall(Accueil)} />
              <Route path="/admin" exact component={LoadOnCall(Admin)} />
              <Route path="/admin/:type" exact render={props => LoadOnCall(Admin, props)()} />
              <Route path="/campaigns" exact component={LoadOnCall(Campaigns)} />
              <Route path="/campaigns/:type" exact render={props => LoadOnCall(Campaigns, props)()} />
              <Route path="/campaigns/:type/:subType" exact render={props => LoadOnCall(Campaigns, props)()} />
              <Route path="/campaigns/:type/:subType/:progType" exact render={props => LoadOnCall(Campaigns, props)()} />
              <Route path="/connect" exact component={LoadOnCall(Connect)} />
              <Route path="/connect/:type" exact render={props => LoadOnCall(Connect, props)()} />
              <Route path="/contacts" exact component={LoadOnCall(Contacts)} />
              <Route path="/contacts/:type" exact render={props => LoadOnCall(Contacts, props)()} />
              <Route path="/contacts/:type/:subType" exact render={props => LoadOnCall(Contacts, props)()} />
              <Route path="/dashboard" exact component={LoadOnCall(Dashboard)} />
              <Route path="/dashboard/:type" exact render={props => LoadOnCall(Dashboard, props)()} />
              <Route path="/landingPages" exact component={LoadOnCall(LandingPages)} />
              <Route path="/landingPages/:type" exact render={props => LoadOnCall(LandingPages, props)()} />
              <Route path="/landingPages/:type/:currentObject" exact render={props => LoadOnCall(LandingPages, props)()} />
              <Route path="/landingPages/:type/:tool/:currentObject" exact render={props => LoadOnCall(LandingPages, props)()} />
              <Route path="/landingPages/:type/:tool/:subType/:currentObject" exact render={props => LoadOnCall(LandingPages, props)()} />
              <Route path="/user" exact component={LoadOnCall(User)} />
              <Route path="/user/:type" exact render={props => LoadOnCall(User, props)()} />
              <Redirect to="/accueil" />
            </Switch>
          </div>
      </div>
    );
  }
}

export default withRouter(App);
