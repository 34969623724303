import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import CSSTransition from "react-transition-group/CSSTransition";

import {isTouchAble} from "../../utils";

import classes from "./Button.module.css";

class AbstractButton extends Component {

	state = {
		clicked: false
	};

	startClick = event => {
		this.setState({clicked: true});
	}

	endClick = () => {
		this.setState({clicked: false});
		if (this.props.onClick)
			this.props.onClick();
	}

	redirect = () => {
		if (this.props.onClick)
			this.props.onClick();
		return <Redirect data-test="button-redirect" to={this.props.to} />;
	};

	isActive = () => (this.props.path && this.props.location 
			&& (
				(typeof this.props.path === "object" && this.props.path.filter(el => this.props.location.pathname.startsWith(el)).length > 0) 
				|| this.props.location.pathname.startsWith(this.props.path)
			)
		);


	timing = {
		enter: 50,
		exit: 200
	};

	defaultDiv = () => (
		<div data-test="component-button" id={this.props.id} style={this.props.style} className={(this.props.className ? this.props.className : classes.Button) + (this.isActive() ? " "+classes.Active : "")}
			{...this.defaultEvents}>
			{this.props.alt ? this.props.alt : this.props.label}
			{this.props.children}
		</div>
	);

	defaultEvents = {
		onTouchStart: isTouchAble() ? this.startClick : null,
		onMouseDown: isTouchAble() ? null : this.startClick,
		onTouchEnd: isTouchAble() ? this.endClick : null,
		onMouseUp: isTouchAble() ? null: this.endClick
	};

	render() {
		if (this.props.noAnim)
			return this.setContent(this.state.clicked)
		return (
			<CSSTransition in={this.state.clicked} timeout={this.timing} classNames={{enterActive: classes.pressed, exitActive: classes.released}}>
				{this.setContent(this.state.clicked)}
			</CSSTransition>
		);
	};
}

export default AbstractButton;