import * as actionTypes from "../actions/actionTypes";

const initialState = {
    destinations: null,
    error: null
}

const reducer = (state=initialState, action) => {
	switch(action.type) {
		case actionTypes.DESTINATIONS:
			return {...state, destinations: action.destinations};
        case actionTypes.DESTINATIONS_FAILED:
            return {...state, error: action.error};
        default: return state;
    }
};

export default reducer;
