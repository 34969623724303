import axios from "axios";
import FileDownload from "js-file-download";
import * as actionTypes from "./actionTypes";
import * as userActions from "./user";

import {server, setFileConfig, setJsonConfig} from "./config";

export const contactsStart = () => (
	{type: actionTypes.CONTACTS_START}
);

export const contactsFailed = error => ({
	type: actionTypes.CONTACTS_FAILED,
	error: error
});

export const contactsPreviewSuccess = (data) => ({
	type: actionTypes.CONTACTS_PREVIEW_SUCCESS,
	preview: data
});

export const contactsImportSuccess = (data) => ({
	type: actionTypes.CONTACTS_IMPORT_SUCCESS,
	tmpList: data
});

export const clearImport = () => ({
	type: actionTypes.CONTACTS_IMPORT_CLEAR
});

export const contactsTmpList = tmpList => ({
	type: actionTypes.CONTACTS_TMP_LIST,
	tmpList
});

export const contactsCurrentList = currentList => ({
	type: actionTypes.CONTACTS_CURRENT_LIST,
	currentList
});

export const contactsClear = () => ({
	type: actionTypes.CONTACTS_CLEAR
});

export const contactsRedirect = redirect => ({
	type: actionTypes.REDIRECT,
	redirect
});

export const clearContactRedirect = () => ({
	type: actionTypes.CLEAR_REDIRECT
});

export const preview = (token, formData) => (async dispatch => {
	dispatch(contactsStart());
    try {
		let response;
		if (formData && formData.has("authorization"))
			response = await axios.post(server+":3500/contacts/preview", formData);
		else 
			response = await axios.post(server+":3500/contacts/preview", formData,  setFileConfig(token));
		dispatch(contactsPreviewSuccess(response.data))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, affichage impossible."));
		}
		dispatch(contactsFailed("Erreur serveur, affichage impossible."));
    }
});

export const importFile = (token, data) => (async dispatch => {
    dispatch(contactsStart());
    try {
		const response = await axios.post(server+":3500/contacts/import", data, setJsonConfig(token));
		dispatch(contactsImportSuccess(response.data.imported));
		dispatch(userActions.userSuccess(token, response.data.user));
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, import impossible."));
		}
		dispatch(contactsFailed("Erreur serveur, import impossible."));
    }
});

export const clearFile = (token, data) => (async dispatch => {
    dispatch(contactsStart());
    try {
		const response = await axios.post(server+":3500/contacts/clearFile", data, setJsonConfig(token));
		if (response.data.result === "ok")
			dispatch(clearImport());
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, suppression impossible."));
		}
		dispatch(contactsFailed("Erreur serveur, suppression impossible."));
    }
});

export const setPage = (token, type, data, redirect) => (async dispatch => {
    dispatch(contactsStart());
    try {
		const response = await axios.post(server+":3500/contacts/pagedList", data, setJsonConfig(token));
		if (type === "tmp")
			dispatch(contactsTmpList(response.data.page));
		else if (type === "current")
			dispatch(contactsCurrentList(response.data.page));
		if (redirect)
			dispatch(contactsRedirect(redirect));
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, affichage impossible."));
		}
		dispatch(contactsFailed("Erreur serveur, affichage impossible."));
    }
});

export const tmpToList = (token, data, redirect) => (async dispatch => {
	 dispatch(contactsStart());
    try {
		const response = await axios.post(server+":3500/contacts/tmpToList", data, setJsonConfig(token));
		dispatch(contactsCurrentList(response.data.page));
		dispatch(userActions.userSuccess(token, response.data.user));
		dispatch(clearImport());
		if (redirect)
			dispatch(contactsRedirect(redirect));
		else
			dispatch(contactsRedirect("/contacts/handle"));
     } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, création impossible."));
		}
		dispatch(contactsFailed("Erreur serveur, création impossible."));
    }
});

export const updateRowField = (token, data) => (async dispatch => {
	dispatch(contactsStart());
   try {
	   const response = await axios.post(server+":3500/contacts/updateRowField", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	} catch(error) {
	   if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, mise à jour impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, mise à jour impossible."));
   }
});

export const renameList = (token, data) => (async dispatch => {
	dispatch(contactsStart());
   try {
	   const response = await axios.post(server+":3500/contacts/renameList", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	   dispatch(userActions.userSuccess(token, response.data.user));
	} catch(error) {
	   if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, changement de nom impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, changement de nom impossible."));
   }
});

export const deleteList = (token, data) => (async dispatch => {
	dispatch(contactsStart());
   try {
	   const response = await axios.post(server+":3500/contacts/deleteList", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	   dispatch(userActions.userSuccess(token, response.data.user));
	} catch(error) {
	   if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, suppression impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, suppression impossible."));
   }
});

export const exportList = (token, name, data) => (async dispatch => {
   try {
	   const response = await axios.post(server+":3500/contacts/exportList", data, {...setJsonConfig(token), responseType: "blob"});
	   FileDownload(response.data, name+".xlsx");
	   dispatch(contactsFailed(null));
	} catch(error) {
	   if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, export impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, export impossible."));
   }
});

export const removeContact = (token, data) => (async dispatch => {
	dispatch(contactsStart());
   try {
	   const response = await axios.post(server+":3500/contacts/removeContact", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	   dispatch(userActions.userSuccess(token, response.data.user));
	} catch(error) {
	   if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, suppression impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, suppression impossible."));
   }
});

export const deleteContact = (token, data) => (async dispatch => {
	dispatch(contactsStart());
   try {
	   const response = await axios.post(server+":3500/contacts/deleteContact", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	   dispatch(userActions.userSuccess(token, response.data.user));
	} catch(error) {
	   if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, suppression impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, suppression impossible."));
   }
});

export const addContact = (token, data) => (async dispatch => {
	dispatch(contactsStart());
    try {
	   const response = await axios.post(server+":3500/contacts/addContact", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	   dispatch(userActions.userSuccess(token, response.data.user));
	   dispatch(contactsRedirect("/contacts/handle"));
	} catch(error) {
		dispatch(contactsRedirect("/contacts/create/form"));
		if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, ajout impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, ajout impossible."));
   }
});

export const updateContact = (token, data) => (async dispatch => {
	dispatch(contactsStart());
    try {
	   const response = await axios.post(server+":3500/contacts/updateContact", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	   dispatch(userActions.userSuccess(token, response.data.user));
	} catch(error) {
		if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
		   return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, mise à jour impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, mise à jour impossible."));
   }
});

export const searchContact = (token, data) => (async dispatch => {
	dispatch(contactsStart());
    try {
	   const response = await axios.post(server+":3500/contacts/search", data, setJsonConfig(token));
	   dispatch(contactsCurrentList(response.data.page));
	   dispatch(contactsRedirect("/contacts/handle/search"));
	} catch(error) {
		if (error.response) {
		   if (error.response.data.disconnect)
			   dispatch(userActions.userDisconnect());
			dispatch(contactsRedirect("/contacts/handle/search"));
			return dispatch(contactsFailed(error.response.data.error || "Erreur serveur, recherche impossible."));
	   }
	   dispatch(contactsFailed("Erreur serveur, recherche impossible."));
	   dispatch(contactsRedirect("/contacts/handle/search"));
   }
});
