import {combineReducers} from "redux";
import user from "./user";
import destinations from "./destinations";
import feries from "./feries";
import contacts from "./contacts";
import landings from "./landings";
import campaigns from "./campaigns";
import campaignResults from "./campaignResults";
import payments from "./payments";

export default combineReducers({
    user, 
    destinations, 
    feries, 
    contacts, 
    landings, 
    campaigns, 
    campaignResults,
    payments
});
