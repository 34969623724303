import axios from "axios";
import * as actionTypes from "./actionTypes";
import * as userActions from "./user";

import {server, setJsonConfig} from "./config";

export const paymentStart = () => (
	{type: actionTypes.LANDINGS_START}
);

export const paymentStop = () => (
	{type: actionTypes.LANDINGS_STOP}
);

export const paymentFailed = error => ({
	type: actionTypes.LANDINGS_FAILED,
	error: error
});

export const paymentSuccess = (data, redirect) => ({
	type: actionTypes.LANDINGS_SUCCESS,
	page: data,
	redirect: redirect ? redirect : null
});

export const clearPayment = () => ({
	type: actionTypes.LANDINGS_CLEAR
});

export const paymentRedirect = redirect => ({
	type: actionTypes.REDIRECT,
	redirect
});

export const clearPaymentRedirect = () => ({
	type: actionTypes.CLEAR_REDIRECT
});

export const posteDataSuccess = (data, redirect) => ({
	type: actionTypes.PAYMENTS_POSTE_DATA,
	data,
	redirect
});

export const posteData = (token, data, redirect) => (async dispatch => {
	dispatch(paymentStart());
	try {
		const response = await axios.post(server+":3500/payments/posteData", data,  setJsonConfig(token));
		dispatch(posteDataSuccess(response.data.posteData, redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(paymentFailed(error.response.data.error || "Serveur indisponible, affichage impossible."));
		}
		dispatch(paymentFailed("Serveur indisponible, affichage impossible."));
    }
});

