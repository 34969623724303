import axios from "axios";
import * as actionTypes from "./actionTypes";
import * as userActions from "./user";

import {server, setJsonConfig} from "./config";

export const campaignsStart = () => (
	{type: actionTypes.CAMPAIGNS_START}
);

export const campaignsStop = () => (
	{type: actionTypes.CAMPAIGNS_STOP}
);

export const campaignsFailed = error => ({
	type: actionTypes.CAMPAIGNS_FAILED,
	error: error
});

export const campaignsSuccess = (data, redirect, newId) => ({
	type: actionTypes.CAMPAIGNS_SUCCESS,
	campaign: data,
	redirect: redirect ? redirect : null,
	newId: newId && newId === true ? true : false
});

export const clearCampaigns = () => ({
	type: actionTypes.CAMPAIGNS_CLEAR
});

export const campaignsRedirect = redirect => ({
	type: actionTypes.REDIRECT,
	redirect
});

export const clearCampaignsRedirect = () => ({
	type: actionTypes.CLEAR_REDIRECT
});

export const campaignsNewId = () => ({
	type: actionTypes.CAMPAIGNS_NEW_ID
});

export const campaignsContacts = (contacts, redirect) => ({
	type: actionTypes.CAMPAIGNS_CONTACTS,
	contacts,
	redirect: redirect ? redirect : null
});

export const setCampaign = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/getCampaign", data, setJsonConfig(token));
		dispatch(campaignsSuccess(response.data.campaign, redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, affichage impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, affichage impossible."));
    }
});

export const saveCampaign = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/saveCampaign", data, setJsonConfig(token));
		dispatch(campaignsSuccess(response.data.campaign, redirect, true))
		dispatch(userActions.userSuccess(token, response.data.user));
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, sauvegarde impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, sauvegarde impossible."));
    }
});

export const duplicateCampaign = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/duplicateCampaign", data, setJsonConfig(token));
		dispatch(campaignsSuccess(response.data.campaign, redirect))
		dispatch(userActions.userSuccess(token, response.data.user));
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, sauvegarde impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, sauvegarde impossible."));
    }
});

export const deleteCampaign = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/deleteCampaign", data, setJsonConfig(token));
		dispatch(clearCampaigns())
		dispatch(campaignsStop());
		dispatch(userActions.userSuccess(token, response.data.user));
		if (redirect)
			dispatch(campaignsRedirect(redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, suppression impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, suppression impossible."));
    }
});

export const addList = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/addList", data, setJsonConfig(token));
		dispatch(campaignsSuccess(response.data.campaign, redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, ajout impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, ajout impossible."));
    }
});

export const removeList = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/removeList", data, setJsonConfig(token));
		dispatch(campaignsSuccess(response.data.campaign, redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, ajout impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, ajout impossible."));
    }
});

export const registerPush = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/registerPush", data, setJsonConfig(token));
		dispatch(campaignsSuccess(response.data.campaign, redirect));
		dispatch(userActions.userSuccess(token, response.data.user));
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, ajout impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, ajout impossible."));
    }
});

export const sendTest = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		await axios.post(server+":3500/campaigns/sendTest", data, setJsonConfig(token));
		dispatch(campaignsStop());
		if (redirect)
			dispatch(campaignsRedirect(redirect))
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			if (redirect)
				dispatch(campaignsRedirect(redirect))
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, ajout impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, ajout impossible."));
    }
});

export const sendSingle = (token, data, redirect) => (async dispatch => {
	dispatch(campaignsStart());
	try {
		const response = await axios.post(server+":3500/campaigns/sendSingle", data, setJsonConfig(token));
		dispatch(campaignsSuccess(response.data.campaign, redirect));
		dispatch(userActions.userSuccess(token, response.data.user));
    } catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userActions.userDisconnect());
			if (redirect)
				dispatch(campaignsRedirect(redirect))
			return dispatch(campaignsFailed(error.response.data.error || "Serveur indisponible, ajout impossible."));
		}
		dispatch(campaignsFailed("Serveur indisponible, ajout impossible."));
    }
});

