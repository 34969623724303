import {v4 as uuid} from "uuid"; 

export const isTouchAble = () => ("ontouchstart" in document.documentElement);

export const numbers = str => {
    const tmp = str.match(/-?\d+/g);
    if (tmp)
        return tmp.join("");;
    return "";
};

export const letters = str => {
    const tmp = str.match(/[a-zA-Z]+/g);
    if (tmp)
        return tmp.join("");;
    return "";
};

export const noSpecial = str => str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/[\\*=;: €¨^£`$%!§(){}°|&@#"'/+-]/g, "_");

export const digits = (num, nb) =>  {
    let zeros = "";
    for (let i=0; i<nb; i++)
        zeros = zeros.concat("0");
    return(zeros+num).slice(nb*-1);
}

export const isValidEmail = email => {
    var regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
};

export const isValidPhone = mobile => { 
    const tmp = numbers(mobile);
    return tmp.length >= 9;
};

export const isEmpty = obj => {
    for (let key in obj)
        if (obj.hasOwnProperty(key))
            return false;
    return true;
}


export const validateForm = (values, rules) => {
    const ids = Object.keys(values);
    for (let id of ids) {
        const value = values[id];
        const validation = rules[id];
        if (validation) {
            for (let validWith of validation) {
                switch (validWith.type) {
                    case "mandatory":
                        if (!value || value === "" || value === "false")
                            return `Le champ ${id} est obligatoire.`;
                        break;
                    case "maxLength":
                        if (value !== "" && value.length > validWith.payload)
                            return `Le champ ${id} doit contenir au maximum ${validWith.payload} caractères.`
                        break;
                    case "minLength":
                        if (value !== "" && value.length < validWith.payload)
                            return `Le champ ${id} doit contenir au moins ${validWith.payload} caractères.`
                        break;
                    case "length":
                        if (value !== "" && value.length !== validWith.payload)
                            return `Le champ ${id} doit contenir ${validWith.payload} caractères.`
                        break;
                    case "isPhone":
                        if (!isValidPhone(value))
                            return "Ce numéro de mobile est incorrect.";
                        break;
                    case "isEmail":
                        if (value !== "" && !isValidEmail(value))
                            return "Cette adresse email est incorrecte.";
                        break;
                    default: break;
                }
            }
        }
    }
    return null;
};

export const origines = {
    971: "https://smsgo.fr:3500/971.png",
    972: "https://smsgo.fr:3500/972.png",
    973: "https://smsgo.fr:3500/973.png",
    974: "https://smsgo.fr:3500/974.png",
    976: "https://smsgo.fr:3500/976.png",
    BE: "https://smsgo.fr:3500/BE.png",
    CI: "https://smsgo.fr:3500/CI.png",
    ES: "https://smsgo.fr:3500/ES.png",
    FR: "https://smsgo.fr:3500/FR.png",
    GB: "https://smsgo.fr:3500/GB.png",
    MA: "https://smsgo.fr:3500/MA.png",
    MD: "https://smsgo.fr:3500/MD.png",
    NC: "https://smsgo.fr:3500/NC.png",
    PO: "https://smsgo.fr:3500/PO.png"
};

export const getTimezone = key => {
    switch(key) {
        case "971": return "America/Guadeloupe";
        case "972": return "America/Martinique";
        case "973": return "America/Cayenne";
        case "974": return "Indian/Reunion";
        case "976": return "Indian/Mayotte";
        case "BE": return "Europe/Brussels";
        case "CI": return "Africa/Abidjan";
        case "ES": return "Europe/Madrid";
        case "FR": return "Europe/Paris";
        case "GB": return "Europe/London";
        case "MA": return "Indian/Mauritius";
        case "MD": return "Indian/Antananarivo";
        case "NC": return "Pacific/Noumea";
        case "PO": return "Pacific/Tahiti";
        default: return "";
    }
}

export const cities = {
    97200: "Fort de France",
    97211: "Rivière Pilote",
    97212: "Saint Joseph",
    97213: "Gros Morne",
    97214: "Lorrain",
    97215: "Rivière Salée",
    97216: "Ajoupa Bouillon",
    97217: "Anses d'Arlet",
    97218: ["Basse Pointe", "Grand Rivière"],
    97220: "Trinité",
    97221: "Carbet",
    97222: ["Bellefontaine", "Case Pilote"],
    97223: "Diamant",
    97224: "Ducos",
    97225: "Marigot",
    97226: "Morne Vert",
    97227: "Sainte Anne",
    97228: "Sainte Luce",
    97229: "Trois Ilets",
    97230: "Sainte Marie",
    97231: ["Robert", "Vert Pré"],
    97232: "Lamentin",
    97233: "Schoelcher",
    97240: "François",
    97250: ["Saint Pierre", "Prêcheur", "Fonds Saint Denis"],
    97260: "Morne Rouge",
    97270: "Saint Esprit",
    97280: "Vauclin",
    97290: "Marin",
    97100: "Basse Terre",
    97110: "Pointe à Pitre",
    97111: "Morne à l'Eau",
    97112: "Grand Bourg",
    97113: "Gourbeyre",
    97114: "Trois Rivières",
    97115: "Sainte Rose",
    97116: "Pointe Noire",
    97117: "Port Louis",
    97118: "Saint François",
    97119: "Vieux Habitants",
    97120: "Saint Claude",
    97121: "Anse Bertrand",
    97122: "Baie Mahault",
    97123: "Baillif",
    97125: "Bouillante",
    97126: "Deshaies",
    97127: "Désirade",
    97128: "Goyave",
    97129: "Lamentin",
    97130: "Capesterre Belle Eau",
    97131: "Petit Canal",
    97133: "Saint Barthélémy",
    97134: "Saint Louis",
    97136: "Terre de Bas",
    97137: "Terre de Haut",
    97139: "Abymes",
    97140: "Capesterre de Marie Galante",
    97141: "Vieux Fort",
    97150: "Saint Martin",
    97160: "Moule",
    97170: "Petit Bourg",
    97180: "Sainte Anne",
    97190: "Gosier",
    97300: "Cayenne",
    97310: "Kourou",
    97311: "Rourg",
    97312: "Saint Elie",
    97313: "Saint Georges de l'Oyapock",
    97314: "Saül",
    97315: "Sinnamary",
    97316: "Papaïchton",
    97317: "Apatou",
    97319: "Awala Yalimapo",
    97320: "Saint Laurent du Maroni",
    97330: "Camopi",
    97340: "Grand Santi",
    97350: "Iracoubo",
    97351: "Matoury",
    97354: "Rémire Montjoly",
    97355: "Macouria",
    97356: "Montsinéry Tonnegrande",
    97360: "Mana",
    97370: "Maripasoula",
    97380: "Ouanary",
    97390: "Régina"
};

export const parseMobile = value => {
	let num = numbers(value);
    if (num === "")
        return num;
    if (num.endsWith("000000"))
        return "";
    switch(num.length) {
        case 14:
            if (
                num.startsWith("00590690") //Guadeloupe
                || num.startsWith("00594694") //Guyane
                || num.startsWith("00596696") //Martinique
                || num.startsWith("00262692") || num.startsWith("00262693") //Reunion
                || num.startsWith("00262639") //Mayotte
                || num.startsWith("0026130")|| num.startsWith("0026132")|| num.startsWith("0026133")|| num.startsWith("0026134") //Madagascar
                || num.startsWith("00447") //Royaume Uni
            )
                return num.substring(2);
            break;
        case 13:
            if (
                num.startsWith("0033") //France metropolitaine
                || num.startsWith("00687") //Nouvelle Caledonie
                || num.startsWith("00689") //Polynesie
                || num.startsWith("002305") //Maurice
                //Cote d'Ivoire
                || num.startsWith("0022501") || num.startsWith("0022502") || num.startsWith("0022503") || num.startsWith("0022504") || num.startsWith("0022505") || num.startsWith("0022506") || num.startsWith("0022507") || num.startsWith("0022508") || num.startsWith("0022509")
                || num.startsWith("0022540") || num.startsWith("0022541") || num.startsWith("0022542") || num.startsWith("0022543") || num.startsWith("0022544") || num.startsWith("0022545") || num.startsWith("0022546") || num.startsWith("0022547") || num.startsWith("0022548") || num.startsWith("0022549")
                || num.startsWith("0022551") || num.startsWith("0022552") || num.startsWith("0022553") || num.startsWith("0022554") || num.startsWith("0022555") || num.startsWith("0022556") || num.startsWith("0022557") || num.startsWith("0022558") || num.startsWith("0022559")
                || num.startsWith("0022571") || num.startsWith("0022574") || num.startsWith("0022575") || num.startsWith("0022576") || num.startsWith("0022577") || num.startsWith("0022578") || num.startsWith("0022579")
                || num.startsWith("0022584") || num.startsWith("0022585") || num.startsWith("0022586") || num.startsWith("0022587") || num.startsWith("0022588") || num.startsWith("0022589")
                || num.startsWith("00346") //Espagne
                || num.startsWith("003245") || num.startsWith("003246") || num.startsWith("003247") || num.startsWith("003248") || num.startsWith("003249") //Belgique
            ) 
                return num.substring(2);
            break;
        case 12:
            if (
                num.startsWith("590690") //Guadeloupe
                || num.startsWith("594694") //Guyane
                || num.startsWith("596696") //Martinique
                || num.startsWith("262692") || num.startsWith("262693") //Reunion
                || num.startsWith("262639") //Mayotte
                || num.startsWith("26130")|| num.startsWith("26132")|| num.startsWith("26133")|| num.startsWith("26134") //Madagascar
                || num.startsWith("447") //Royaume Uni
            )
                return num; 
            break;
        case 11:
            if (
                num.startsWith("33") //France metropolitaine
                || num.startsWith("687") //Nouvelle Caledonie
                || num.startsWith("689") //Polynesie
                || num.startsWith("2305") //Maurice
                //Cote d'Ivoire
                || num.startsWith("22501") || num.startsWith("22502") || num.startsWith("22503") || num.startsWith("22504") || num.startsWith("22505") || num.startsWith("22506") || num.startsWith("22507") || num.startsWith("22508") || num.startsWith("22509")
                || num.startsWith("22540") || num.startsWith("22541") || num.startsWith("22542") || num.startsWith("22543") || num.startsWith("22544") || num.startsWith("22545") || num.startsWith("22546") || num.startsWith("22547") || num.startsWith("22548") || num.startsWith("22549")
                || num.startsWith("22551") || num.startsWith("22552") || num.startsWith("22553") || num.startsWith("22554") || num.startsWith("22555") || num.startsWith("22556") || num.startsWith("22557") || num.startsWith("22558") || num.startsWith("22559")
                || num.startsWith("22571") || num.startsWith("22574") || num.startsWith("22575") || num.startsWith("22576") || num.startsWith("22577") || num.startsWith("22578") || num.startsWith("22579")
                || num.startsWith("22584") || num.startsWith("22585") || num.startsWith("22586") || num.startsWith("22587") || num.startsWith("22588") || num.startsWith("22589")
                || num.startsWith("346") //Espagne
                || num.startsWith("3245") || num.startsWith("3246") || num.startsWith("3247") || num.startsWith("3248") || num.startsWith("3249") //Belgique
            )
                return num;
            break;
        case 10:
            if (num.startsWith("0696"))
                return "596"+num.substring(1);
            if (num.startsWith("0690"))
                return "590"+num.substring(1);
            if (num.startsWith("0694")) 
                return "594"+num.substring(1);
            if (num.startsWith("0692") || num.startsWith("0693") || num.startsWith("0639")) 
                return "262"+num.substring(1);
            if (num.startsWith("06") || num.startsWith("07")) 
                return "33"+num.substring(1);
            break;
        case 9:
            if (num.startsWith("696"))
                return "596"+num;
            if (num.startsWith("690"))
                return "590"+num;
            if (num.startsWith("694")) 
                return "594"+num;
            if (num.startsWith("692") || num.startsWith("693") || num.startsWith("639")) 
                return "262"+num;
            if (num.startsWith("6") || num.startsWith("7")) 
                return "33"+num;
            break;
    
        default: return "";
    }
    return "";
};

export const getOrigine = mobile => {

    if (mobile) {
        switch(mobile.length) {
            case 12:
                if (mobile.startsWith("590690"))
                    return {label: "Guadeloupe", icon: "971"};
                if (mobile.startsWith("596696"))
                    return {label: "Martinique", icon: "972"};
                if (mobile.startsWith("594694"))
                    return {label: "Guyane", icon: "973"};
                if (mobile.startsWith("262692") || mobile.startsWith("262693"))
                    return {label: "Réunion", icon: "974"};
                if (mobile.startsWith("262639"))
                    return {label: "Mayotte", icon: "976"};
                if (mobile.startsWith("26130")|| mobile.startsWith("26132")|| mobile.startsWith("26133")|| mobile.startsWith("26134"))
                    return {label: "Madagascar", icon: "MD"};
                if (mobile.startsWith("447"))
                    return {label: "Royaume Uni", icon: "GB"};
            break;
            case 11:
                if (mobile.startsWith("33"))
                    return {label: "France métropolitaine", icon: "FR"};
                if (mobile.startsWith("687"))
                    return {label: "Nouvelle Calédonie", icon: "NC"};
                if (mobile.startsWith("689"))
                    return {label: "Polynésie française", icon: "PO"};
                if (mobile.startsWith("2305"))
                    return {label: "Mauritius", icon: "MA"};
                if (mobile.startsWith("22501") || mobile.startsWith("22502") || mobile.startsWith("22503") || mobile.startsWith("22504") || mobile.startsWith("22505") || mobile.startsWith("22506") || mobile.startsWith("22507") || mobile.startsWith("22508") || mobile.startsWith("22509")
                    || mobile.startsWith("22540") || mobile.startsWith("22541") || mobile.startsWith("22542") || mobile.startsWith("22543") || mobile.startsWith("22544") || mobile.startsWith("22545") || mobile.startsWith("22546") || mobile.startsWith("22547") || mobile.startsWith("22548") || mobile.startsWith("22549")
                    || mobile.startsWith("22551") || mobile.startsWith("22552") || mobile.startsWith("22553") || mobile.startsWith("22554") || mobile.startsWith("22555") || mobile.startsWith("22556") || mobile.startsWith("22557") || mobile.startsWith("22558") || mobile.startsWith("22559")
                    || mobile.startsWith("22571") || mobile.startsWith("22574") || mobile.startsWith("22575") || mobile.startsWith("22576") || mobile.startsWith("22577") || mobile.startsWith("22578") || mobile.startsWith("22579")
                    || mobile.startsWith("22584") || mobile.startsWith("22585") || mobile.startsWith("22586") || mobile.startsWith("22587") || mobile.startsWith("22588") || mobile.startsWith("22589")
                )
                    return {label: "Côte d'Ivoire", icon: "CI"};
                if (mobile.startsWith("346"))
                    return {label: "Espagne", icon: "ES"};
                if (mobile.startsWith("3245") || mobile.startsWith("3246") || mobile.startsWith("3247") || mobile.startsWith("3248") || mobile.startsWith("3249"))
                    return {label: "Belgique", icon: "BE"};
            break;
            default:
                return {label: "France métropolitaine", icon: "FR"};
        }
    }
    return {label: "France métropolitaine", icon: "FR"};
};

export const serialize = obj => {
	if (typeof obj === "string") return obj;
	
	const events = {};
	if (obj.events)
		for (let event of Object.keys(obj.events))
			events[event] = obj.events[event].toString();

	return JSON.stringify(createElement({...obj, 
		attributes: JSON.stringify({...obj.attributes}),
        style: JSON.stringify({...obj.style}),
		children: obj.children.map(el => serialize(el)).join("|"), 
		events: JSON.stringify(events)
	}));
};

export const deserialize = json => {
	const ori = JSON.parse(json);
	const events = JSON.parse(ori.events)
	for (let event of Object.keys(events))
		/* eslint-disable no-new-func */
		events[event] = new Function("return " + events[event])();
	return createElement({...ori, 
		attributes: JSON.parse(ori.attributes),
		style: JSON.parse(ori.style),
		events: events,
		children: (ori.children.indexOf("|") === -1 ? [ori.children] : 
			ori.children.split("|")).map(el => el.startsWith("{") ? deserialize(el) : el)
	});
}

export const createElement = init => ({
    type: init.type || "div",
    key: init.key || uuid(),
    name: init.name || "",
    tool: init.tool || null,
    className: init.className || "",
    attributes: init.attributes || {},
    style: init.style || {},
    text: init.text || null,
    children: init.children || null,
    events: init.events || {}
});

export const scrollTo = (rootId, currentId) => {
    const root = document.getElementById(rootId);
    const current = document.getElementById(currentId);
    if (current) {
        const top = root.scrollTop + current.getBoundingClientRect().top - root.getBoundingClientRect().top;
        document.getElementById(rootId).scrollTo({top, left: 0, behavior: "smooth"});
    }
}