import axios from "axios";

import * as actionTypes from "./actionTypes";
import * as contactsActions from "./contacts";

import Config from "./config";

export const userStart = () => (
	{type: actionTypes.USER_START}
);

export const userFailed = error => ({
	type: actionTypes.USER_FAILED,
	error: error
});

export const userSuccess = (token, data, message) => ({
	type: actionTypes.USER_SUCCESS,
	data,
	token,
	message
});

export const userClear = () => ({
	type: actionTypes.USER_CLEAR
});

export const userConnect = redirect => ({
	type: actionTypes.USER_CONNECT,
	redirect: redirect ? redirect : null
});

export const userDisconnect = () => ({
	type: actionTypes.USER_DISCONNECT
});

export const updateList = (name, list) => ({
	type: actionTypes.USER_UPDATE_LIST,
	name,
	list
});

export const userRedirect = redirect => ({
	type: actionTypes.REDIRECT,
	redirect
});

export const clearUserRedirect = () => ({
	type: actionTypes.CLEAR_REDIRECT
});

export const autolog = (token, redirect) => (async dispatch => {
	if (!token)
		return dispatch(userClear());
	try {
		const response = await axios.get(Config.server+":3500/user/byToken", Config.setConfig(token));
		dispatch(userSuccess(response.headers["authorization"], response.data))
		localStorage.setItem("token", response.headers["authorization"]);
		dispatch(userConnect(redirect));
	} catch(error) {
		localStorage.removeItem("token");
		dispatch(userClear());
	}
});

export const login = (credentials) => (async dispatch => {
	dispatch(userClear());
	dispatch(contactsActions.contactsClear());
	dispatch(userStart());
	try {
		const response = await axios.post(Config.server+":3500/user/login", credentials, Config.setConfig());
		dispatch(userSuccess(response.headers["authorization"], response.data))
		localStorage.setItem("token", response.headers["authorization"]);
		dispatch(userConnect());
	} catch(error) {
		if (error.response)
			return dispatch(userFailed(error.response.data.error || "Erreur serveur, connexion impossible."));
		dispatch(userFailed("Erreur serveur, connexion impossible."));
	}
});

export const subscribe = user => (async dispatch => { 
	dispatch(userClear());
	dispatch(userStart());
	try {
		const response = await axios.post(Config.server+":3500/user/subscribe", user, Config.setConfig());		
		dispatch(userSuccess(response.headers["authorization"], response.data));
		dispatch(userConnect());
		await axios.get("http://www.axxescaraibes.com:8088/Smsgo/WebServer?action=method&methodName=recService&scratId="+response.data.scratId);
	} catch(error) {
		if (error.response)
			return dispatch(userFailed(error.response.data.error || "Erreur serveur, inscription impossible."));
		dispatch(userFailed("Erreur serveur, inscription impossible."));
	}
});

export const byInfos = (infos) => (async dispatch => {
	dispatch(userClear());
	dispatch(userStart());
	try {
		const response = await axios.post(Config.server+":3500/user/byInfos", infos, Config.setConfig());
		dispatch(userSuccess(response.headers["authorization"], response.data))
	} catch(error) {
		if (error.response)
			return dispatch(userFailed(error.response.data.error || "Erreur serveur, connexion impossible."));
		dispatch(userFailed("Erreur serveur, connexion impossible."));
	}
});

export const updateFields = (token, infos) => async dispatch => {
	dispatch(userStart());
	try {
		const response = await axios.post(Config.server+":3500/user/updateFields", infos, Config.setConfig(token));		
		dispatch(userSuccess(response.headers["authorization"], response.data, 'Informations modifiées avec succès'))
		
	} catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userDisconnect());
			return dispatch(userFailed(error.response.data.error || "La mise à jour n'a pas pu être effecutée."));
		}
		dispatch(userFailed("La mise à jour n'a pas pu être effecutée."));
	}
	
};

export const resetPassword = (token, infos) => async dispatch => { 	
	dispatch(userStart());
	try {
		const response = await axios.post(Config.server+":3500/user/resetPassword", infos, Config.setConfig(token));		
		dispatch(userSuccess(response.headers["authorization"], response.data))
		dispatch(userConnect());
	} catch(error) {
		if (error.response) {
			if (error.response.data.disconnect)
				dispatch(userDisconnect());
			return dispatch(userFailed(error.response.data.error || "La mise à jour n'a pas pu être effecutée."));
		}
		dispatch(userFailed("La mise à jour n'a pas pu être effecutée."));
	}
	
};

export const logout = token => (async dispatch => {
	dispatch(userStart());
	try {
		await axios.get(Config.server+":3500/user/logout", Config.setConfig(token));
	} catch(error) {}
	localStorage.removeItem("token");
	dispatch(userClear());
	dispatch(contactsActions.contactsClear());
});

export const deleteUser = (token, infos) => (async dispatch => {
	dispatch(userStart());
	try {
		await axios.post(Config.server+":3500/user/delete", infos, Config.setConfig(token));
	} catch(error) {}
	dispatch(userClear());
	dispatch(contactsActions.contactsClear());
});